import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery, useMutation } from 'react-query';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import showToast from 'components/Toast/Toast';
import { OktaGroupProfile as OktaGroupProfileSchema } from 'api/auth/schema';
import { OktaGroupProfile } from 'api/companies/schema';
import { getGroup, updateGroup, createGroup } from 'api/companies/api';
import { LoaderGranite } from 'components';

interface ManageCompanyDialogProps {
  isOpen: boolean;
  onClose: () => void;
  dynamicProps?: {
    groupId: string;
  };
  onCompanySuccess: () => void;
}

export const ManageCompanyDialog: React.FC<ManageCompanyDialogProps> = ({
  isOpen,
  onClose,
  dynamicProps,
  onCompanySuccess,
}) => {
  const isEdit = Boolean(dynamicProps?.groupId);
  const [isFetching, setIsFetching] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm<OktaGroupProfile>({
    resolver: zodResolver(OktaGroupProfileSchema),
    defaultValues: {
      display_name: '',
      name: '',
      description: null,
      cw_company_id: [],
      info: { cw_links: [{ macnum: null, type: '', id: undefined }] },
    },
  });

  const { isLoading } = useQuery(
    ['group', dynamicProps?.groupId],
    () => getGroup(dynamicProps?.groupId as string),
    {
      enabled: isEdit,
      onSuccess: (data) => {
        setIsFetching(false);
        setValue('display_name', data.display_name);
        setValue('name', data.name);
        setValue('description', data.description);
        setValue('cw_company_id', data.cw_company_id);

        const cwLink = data.info?.cw_links?.[0] || {};
        setValue('info.cw_links.0.macnum', cwLink.macnum || '');
        setValue('info.cw_links.0.id', cwLink.id || '');
        setValue('info.cw_links.0.type', cwLink.type || '');
      },
      onSettled: () => {
        setIsFetching(false);
      },
    },
  );

  useEffect(() => {
    if (!isEdit) {
      reset();
    } else {
      setIsFetching(true);
    }
  }, [isEdit, reset]);

  const mutation = useMutation(
    (payload: OktaGroupProfile) =>
      isEdit
        ? updateGroup(payload, dynamicProps?.groupId as string)
        : createGroup(payload),
    {
      onSuccess: () => {
        showToast.confirmation({
          message: `Company ${isEdit ? 'updated' : 'added'} successfully!`,
        });
        onClose();
        onCompanySuccess();
      },
      onError: ({ response }) => {
        showToast.error({
          message: response.data.message ?? 'Something went wrong',
        });
      },
    },
  );

  const onSubmit = (payload: OktaGroupProfile) => mutation.mutate(payload);

  const onError = (err: unknown) => {
    console.log('FormError:', err);
    return;
  };

  const onModalClose = () => {
    reset({});
    onClose();
  };

  return (
    <Modal
      isVisible={isOpen}
      close={onModalClose}
      className="w-full max-w-[624px]"
      enableScrolling={true}
    >
      <div className="flex h-full flex-col gap-5 overflow-y-auto rounded bg-background-base-surface-2 p-8 shadow">
        <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
          {isEdit ? 'Edit company' : 'Add company'}
        </h1>
        {isLoading || isFetching ? (
          <LoaderGranite className="m-auto" />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="space-y-6"
          >
            <GraniteInput
              label="Company ID"
              innerInputClassName="w-full"
              className="w-full"
              placeholder="Company ID"
              {...register('cw_company_id.0')}
              error={errors.cw_company_id?.message}
            />
            <GraniteInput
              label="Company name"
              placeholder="Company Name"
              {...register('name')}
              error={errors.name?.message}
            />
            <GraniteInput
              label="Company display name"
              placeholder="Display Name"
              {...register('display_name')}
              error={errors.display_name?.message}
            />
            <GraniteInput
              label="Company macnum"
              placeholder="Macnum"
              {...register('info.cw_links.0.macnum')}
              error={errors.info?.cw_links?.[0]?.macnum?.message}
            />
            <GraniteInput
              label="Link id"
              innerInputClassName="w-full"
              className="w-full"
              placeholder="Link id"
              {...register('info.cw_links.0.id')}
              error={errors.info?.cw_links?.[0]?.id?.message}
            />
            <GraniteInput
              label="Link type"
              innerInputClassName="w-full"
              className="w-full"
              placeholder="Link type"
              {...register('info.cw_links.0.type')}
              error={errors.info?.cw_links?.[0]?.id?.message}
            />
            <GraniteInput
              label="Description"
              placeholder="Description"
              {...register('description')}
              error={errors.description?.message}
            />
            <div className="flex justify-end gap-4">
              <GraniteButton variant="secondary" onClick={onClose}>
                Cancel
              </GraniteButton>
              <GraniteButton
                variant="primary"
                type="submit"
                size="large"
                disabled={mutation.isLoading}
              >
                Save Changes{' '}
              </GraniteButton>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};
