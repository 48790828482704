import {
  G360Pillars,
  TokenUserRoles,
  canViewAccessExpress,
} from '../api/users/schemas/Users';
import { useCallback, useMemo } from 'react';
import { useAuth } from 'authContext';
import { G360Token } from 'api/auth/schema';

interface CWContact {
  user_id: number;
  company_id: number;
  macnum: string;
}

export interface GroupDetail {
  role: TokenUserRoles;
  group_id: string;
  group_name: string;
  cw_contacts: CWContact[];
}

export const useAuthUser = () => {
  const { user } = useAuth();

  const {
    email,
    email_verified,
    name,
    sub,
    given_name: first_name,
    family_name: last_name,
  } = user || {};

  const g360Token: G360Token = JSON.parse(
    localStorage.getItem('g360-token-storage') ?? '{}',
  );
  const { permissions, group_id, group_metadata } = g360Token;
  // TODO: handle more than one cw_link
  const companyId = group_metadata?.info?.cw_links?.[0]?.id;
  const macnum = group_metadata?.info?.cw_links?.[0]?.macnum;
  const companyName = group_metadata?.display_name;

  const roles = useMemo(() => {
    if (g360Token.roles) {
      const _roles = [...g360Token.roles];
      if (g360Token.super_admin) _roles.push(TokenUserRoles.SUPER_ADMIN);
      return _roles;
    }
    return [];
  }, [g360Token]);

  const pillars = useMemo(() => {
    const pillarsSet = new Set<G360Pillars>();
    if (roles.some((role) => canViewAccessExpress(role))) {
      pillarsSet.add(G360Pillars.AccessExpress);
    }
    return pillarsSet;
  }, [roles]);

  const hasPillarAccess = useCallback(
    (pillar: G360Pillars) => {
      return roles.includes(TokenUserRoles.SUPER_ADMIN) || pillars.has(pillar);
    },
    [pillars, roles],
  );

  const hasRoleAssigned = useMemo(() => {
    return roles.some((role) => Object.values(TokenUserRoles).includes(role));
  }, [roles]);

  const isQRAdminRole = useMemo(() => {
    return roles.some((role) => role === TokenUserRoles.QR_ADMIN);
  }, [roles]);

  const isViewerRole = useMemo(() => {
    return roles.some((role) => role === TokenUserRoles.VIEWER);
  }, [roles]);

  const isProspectRole = useMemo(() => {
    return roles.some((role) => role === TokenUserRoles.PROSPECT);
  }, [roles]);

  return {
    email,
    email_verified,
    name,
    first_name,
    last_name,
    group_id,
    sub,
    companyId,
    companyName,
    roles,
    permissions,
    pillars,
    hasPillarAccess,
    hasRoleAssigned,
    isQRAdminRole,
    macnum,
    isViewerRole,
    isProspectRole,
  };
};
